
import { Options, Vue } from 'vue-class-component'
import TournamentAdvancedMatchSetRoundsMode from '@/components/Tournaments/TournamentAdvancedMatchSetRoundsMode/index.vue' // @ is an alias to /src

@Options({
  components: {
    TournamentAdvancedMatchSetRoundsMode
  }
})
export default class TournamentAdvancedMatchSetRoundsModeView extends Vue {
  // onEnlargeText() {
  //   console.log("enlarging text");
  // }
}
